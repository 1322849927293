import React from 'react';

import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ApplyButton from '../ApplyButton2';

const LocationJob = ({ job = {}, doViewDetail = () => {}, passThru, brand }) => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={6}
        md={12}
        lg={6}
        justifyContent={useMediaQuery(theme.breakpoints.down('md')) ? 'center' : 'flex-start'}
      >
        <Typography variant="body2" style={{ fontFamily: 'proxima-nova-semi' }}>
          {job.title}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={5}
        md={12}
        lg={6}
        justifyContent={useMediaQuery(theme.breakpoints.down('md')) ? 'center' : 'flex-end'}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              size="small"
              fullWidth={useMediaQuery(theme.breakpoints.down('md'))}
              onClick={doViewDetail(job.id)}
              variant="contained"
              color="secondary"
            >
              View Job
            </Button>
            <ApplyButton
              orgId={job.orgId}
              jobId={job.id}
              passThru={passThru}
              fullWidth={useMediaQuery(theme.breakpoints.down('md'))}
              size="small"
              label="Quick Apply!"
              buttonClass=""
              style={{ marginLeft: '12px' }}
              brandName={brand?.name}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LocationJob;
