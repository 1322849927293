import React from 'react';
import { matchSorter } from 'match-sorter';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import CloseIcon from '@material-ui/icons/Close';

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, { keys: ['label'] });

const useStyles = makeStyles(() => ({
  autocomplete: {
    marginTop: '6px',
    marginBottom: '8px',
  },
}));

const AutocompleteDropDown = ({
  label,
  value,
  onChange,
  options,
  req = false,
  error = {},
  disabled = false,
  variant = 'outlined',
  multiple = false,
  limitTags = 3,
  autoHighlight = false,
  font,
  inputClass,
  style = {},
  customClasses = '',
  disableLabelTransform = false,
}) => {
  const classes = useStyles();
  let controlOptions = options || [];

  // console.log(`value: ${value}`);

  let isError = false;
  if (error.isValid !== undefined && !error.isValid) isError = true;

  // check if options is an array; if not it might be an enum object, turn it into an array
  if (controlOptions && !Array.isArray(controlOptions)) {
    const newOptions = [];

    Object.keys(controlOptions).forEach((key) => {
      newOptions.push(controlOptions[key]);
    });

    controlOptions = newOptions;
  }

  // instead of red start for required, we'll add "optional" to non-required fields
  let myLabel = label;
  if (typeof req === 'boolean' && req === false && label && label.length > 0) {
    myLabel = `${label} (optional)`;
  }

  const inputLabelProps = {
    shrink: true,
  };

  if (disableLabelTransform) {
    inputLabelProps.style = {
      textTransform: 'none',
    };
  }

  return (
    <div className={classes.autocomplete}>
      <Autocomplete
        className={customClasses}
        autoHighlight={autoHighlight}
        multiple={multiple}
        limitTags={limitTags}
        filterOptions={filterOptions}
        value={value || ''}
        onChange={onChange}
        options={controlOptions}
        style={style}
        disabled={disabled}
        ChipProps={{ deleteIcon: <CloseIcon className="close-button" /> }}
        getOptionLabel={(option) => {
          // console.log(`getOptionLabel: ${JSON.stringify(option)}`);
          // console.log(controlOptions);
          const retrieved = _.find(
            controlOptions,
            (x) => (x.value || '').toLowerCase() === (option.value || option || '').toLowerCase(),
          );
          // console.log(`retrieved: ${JSON.stringify(retrieved)}`);
          return retrieved ? retrieved.label : '';
        }}
        getOptionSelected={(option, val) => option?.value === val}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    fontFamily: font,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={inputLabelProps}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              className: inputClass,
            }}
            error={isError}
            required={req}
            label={myLabel}
            variant={variant}
          />
        )}
      />
    </div>
  );
};

export default AutocompleteDropDown;
