import React from 'react';

import { useTheme, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ApplyButton from '../ApplyButton2';

const JobDetailsBox = React.memo(({ job, brand, passThru = {} }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      className={isXs ? 'job-description-box chattrCardNoBorder' : 'job-description-box chattrCard'}
      style={isSm ? { maxWidth: '100%' } : { maxWidth: '356px' }}
      id="jobDetailsBox"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="job-description-box-subtitle">Location</Typography>

          {`${job.location.street}, ${job.location.city}, ${job.location.state} ${job.location.zipCode}`}
        </Grid>
        <Grid item xs={12}>
          <Typography className="job-description-box-subtitle">Sector</Typography>
          {`${job.category.name}`}
        </Grid>
        <Grid item xs={12}>
          <Typography className="job-description-box-subtitle">Availability</Typography>
          {`${job.type}`}
        </Grid>
        <Grid item xs={12}>
          <Typography className="job-description-box-subtitle">Compensation</Typography>
          Based on experience
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: '12px', textAlign: 'center' }}>
            <ApplyButton
              orgId={job.orgId}
              jobId={job.id}
              passThru={passThru}
              size="small"
              label="Quick Apply Now!"
              buttonClass=""
              brandName={brand?.name}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

export default JobDetailsBox;
