import React from 'react';
import _filter from 'lodash/filter';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Autocomplete from './AutocompleteDropDown';

const JobFiltersHorizontal = React.memo(
  ({ filters = {}, states, cities, handleFilterChange = () => {}, referrer }) => {
    if (filters.stateFilter !== '') {
      cities = _filter(cities, (x) => x.state.toLowerCase() === filters.stateFilter.toLowerCase());
    }

    return (
      <Grid container spacing={2} className="filter-card filter-card-horizontal">
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            variant="outlined"
            label="State"
            value={filters.stateFilter || ''}
            onChange={handleFilterChange('stateFilter')}
            options={states}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            variant="outlined"
            label="City"
            value={filters.cityFilter || ''}
            onChange={handleFilterChange('cityFilter')}
            options={cities}
          />
        </Grid>

        {referrer && (
          <Grid container item xs={12} justifyContent="center">
            <Typography variant="caption">
              <strong>Referred By:</strong> {referrer}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  },
);

export default JobFiltersHorizontal;
