import React from 'react';
import Link from 'next/link';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import Chattrtar from '../Chattrtar';
import Pager from '../Pager';

const columnOneName = 'Job';
const columnTwoName = 'Location';
const columnThreeName = '';

const JobTable = (props) => {
  const { hasJobs, org, jobs, paging, brand, uniqueAvatars } = props;

  return (
    <>
      {hasJobs && (
        <>
          <TableContainer className="job-table-container">
            <Table className="job-table">
              <TableHead className="job-table-header">
                <TableRow style={{ backgroundColor: brand.orgColor }}>
                  <TableCell>{columnOneName}</TableCell>
                  <TableCell>{columnTwoName}</TableCell>
                  <TableCell align="center">{columnThreeName}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs
                  .slice(paging.startIndex, paging.startIndex + paging.pageSize)
                  .map((job, idx) => (
                    <TableRow key={idx}>
                      <TableCell data-label={columnOneName}>
                        <div>
                          {uniqueAvatars.length > 1 && (
                            <Chattrtar avatarUri={brand.avatarUri} size="small" />
                          )}
                          <div>
                            <div className="job-table-title">{job.title}</div>
                            <div className="job-table-detail">
                              {brand.name} - {job.type}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell data-label={columnTwoName}>
                        <div className="job-table-location">{`${job.location.city}, ${job.location.state}, ${job.location.zipCode}`}</div>
                        <div className="job-table-street">{`${job.location.street}`}</div>
                      </TableCell>
                      <TableCell data-label={columnThreeName} align="center">
                        <div className="job-button">
                          <Link
                            href={{ pathname: '/[orgId]/[jobId]', query: props.query }}
                            as={`/${org.url}/${job.id}${props.queryString}`}
                            passHref
                            prefetch={false}
                          >
                            <Button
                              className="theme-button"
                              style={{ backgroundColor: brand.orgColor }}
                              color="primary"
                              variant="contained"
                            >
                              View Job
                            </Button>
                          </Link>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {jobs.length > paging.pageSize && (
            <Pager paging={paging} onPage={props.onPage} jobCount={jobs.length} />
          )}
        </>
      )}

      {hasJobs === false && (
        <div className="centerIt">
          <div className="chattrCard noJobs">No positions are currently open. Check back soon!</div>
        </div>
      )}
    </>
  );
};

export default JobTable;
