const haversine = require('haversine-distance');
const fetch = require('isomorphic-unfetch');

/**
 * @description Gets straight line "as the crow flies" distance between 2 sets of lat/long
 * @param {object} coords1 - { lat, lng }
 * @param {object} coords2 - { lat, lng }
 * @returns {number} distance (in miles)
 */
const getDistance = (coords1, coords2) => {
  let miles = 0;

  if (coords1 && coords2) {
    const dist = haversine(coords1, coords2);
    miles = dist * 0.00062137;
  }

  return miles;
};

const getYummyCenter = async (zipCode) => {
  // apparently this function name is a reference to Tootsie Pops...wtf Jeff -CB
  let coords;
  let resp = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=AIzaSyCyeyrDWeRD5amlNPLwjGw1b6XdMqZ1GtU`,
  );
  resp = await resp.json();

  if (resp && resp.results && resp.results[0] && resp.results[0].geometry) {
    coords = resp.results[0].geometry.location;
  }
  return coords;
};

module.exports = {
  getDistance,
  getYummyCenter,
};
