import React from 'react';
import Container from '@material-ui/core/Container';

const JobDescription = ({ org, moveUp = false, naked = true, width = 'md' }) => {
  // let classes = (naked) ? `content-container-${width}` : `chattrCard content-container-${width}`;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {org.description && (
        <Container maxWidth={width} style={{ marginTop: moveUp ? '-48px' : '56px' }}>
          <div className="orgDescTitle">About Us</div>
          <div className="orgDesc" dangerouslySetInnerHTML={{ __html: org.description }} />
        </Container>
      )}
    </>
  );
};

export default JobDescription;
