/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';

import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import IconLeft from '@material-ui/icons/ArrowLeft';
import Typography from '@material-ui/core/Typography';

import ApplyButton from '../ApplyButton2';
import JobDetailsBox from './JobDetailsBox';

// https://stackoverflow.com/questions/15850271/how-to-make-div-fixed-after-you-scroll-to-that-div

export default ({ job, brand, goBack, passThru }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (!isXs) {
      const doOnScroll = () => {
        const elem = document.getElementById('jobDetailsBox');
        const elem2 = document.getElementById('jobDescriptionBox');

        if (elem2) {
          if (elem2.getBoundingClientRect().top <= 0) {
            elem.style.position = 'fixed';
            elem.style.top = '12px';
          } else {
            elem.style.position = 'relative';
            elem.style.top = '0';
          }
        }
      };

      window.onscroll = doOnScroll;
    }
  }, []);

  return (
    <Container maxWidth="lg" className="job-description-wrapper" style={{ marginTop: '32px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button onClick={goBack} startIcon={<IconLeft />}>
            View All Openings
          </Button>
        </Grid>

        <Hidden mdUp>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography className="job-description-title" variant="h5">
              {job.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <JobDetailsBox job={job} brand={brand} passThru={passThru} />
          </Grid>
        </Hidden>

        <Grid item xs={12} md={8}>
          <div
            className={isXs ? 'job-description chattrCardNoBorder' : 'job-description chattrCard'}
            id="jobDescriptionBox"
          >
            <Grid container spacing={2}>
              <Hidden smDown>
                <Grid container item className="job-description-header">
                  <Grid item>
                    <Typography className="job-description-title" variant="h5">
                      {job.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ApplyButton
                      orgId={job.orgId}
                      jobId={job.id}
                      passThru={{}}
                      size="small"
                      label="Quick Apply Now!"
                      buttonClass=""
                      brandName={brand?.name}
                    />
                  </Grid>
                </Grid>
              </Hidden>

              <Grid item xs={12}>
                <div
                  className="job-description-text"
                  dangerouslySetInnerHTML={{ __html: job.description }}
                />
              </Grid>
              <Grid item container xs={12} justifyContent="center">
                <ApplyButton
                  orgId={job.orgId}
                  jobId={job.id}
                  passThru={{}}
                  size="small"
                  label="Quick Apply Now!"
                  buttonClass=""
                  brandName={brand?.name}
                />
              </Grid>
              <Grid item xs={12}>
                <span className="caption">Job Ref #: {job.id}</span>
                <br />
                <span className="caption">Date Posted: {job.postedDtStr}</span>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ top: 0, position: 'sticky' }}>
          <Hidden smDown>
            <JobDetailsBox job={job} brand={brand} passThru={passThru} />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};
