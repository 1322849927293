import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

// docs
// https://react-google-maps-api-docs.netlify.app/#marker
// marker color options: https://sites.google.com/site/gmapsdevelopment/
// https://developers.google.com/maps/documentation/javascript/markers#complex_icons

const Map = ({ center, width = '100%', height = '525px', markers = [], zoom = 10 }) => {
  // console.log(center, zoom);

  return (
    <LoadScript googleMapsApiKey="AIzaSyCas96-rHdY8JVdXVR0hkxoKEWTJLhOeYY">
      <GoogleMap
        mapContainerStyle={{ width, height }}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
        center={center}
        zoom={zoom}
      >
        <>
          {markers.map((x, idx) => {
            return <span key={idx}>{x}</span>;
          })}
        </>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
