import React from 'react';

import Container from '@material-ui/core/Container';

const FranchiseHeader = React.memo((props) => (
  <div>
    <Container maxWidth="lg">
      <div className="hero-header-top">
        <img className="hero-header-logo" alt="company logo" src={props.companyLogo} />
      </div>
    </Container>
    <div className="hero-header-bg" style={{ backgroundImage: `url(${props.companyBackground})` }}>
      <Container maxWidth="lg">
        <div className="hero-header-title">{`Careers with ${props.companyName}`}</div>
      </Container>
    </div>
  </div>
));

export default FranchiseHeader;
