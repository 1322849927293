/* eslint-disable react/no-danger */
import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import JobItem from '../JobItem2';
import JobFilters from '../JobFilters';
import Pager from '../Pager';

const ListBasic = (props) => {
  const { org, jobs, hasJobs, showFilters, uniqueAvatars, showHeaderAvs, brand } = props;

  return (
    <div className="theme theme-classic">
      {org.description && (
        <div className="org-desc-wrapper">
          <Container maxWidth="lg">
            <div className="orgDesc" dangerouslySetInnerHTML={{ __html: org.description }} />
          </Container>
        </div>
      )}

      {hasJobs === true && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={showFilters ? 4 : 12}>
              {showFilters === true && (
                <div className="filters-wrapper">
                  <JobFilters
                    filters={props.filters}
                    states={props.states}
                    cities={props.cities}
                    handleFilterChange={props.handleFilterChange}
                    referrer={props.referrer}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={showFilters ? 8 : 12}>
              <div className="jobs-container">
                {jobs
                  .slice(props.paging.startIndex, props.paging.startIndex + props.paging.pageSize)
                  .map((job, idx) => (
                    <JobItem
                      key={idx}
                      org={org}
                      job={job}
                      brand={brand}
                      source={props.source}
                      campaign={props.campaign}
                      phone={props.phone}
                      query={props.query}
                      queryString={props.queryString}
                      uniqueAvatars={uniqueAvatars}
                      showHeaderAvs={showHeaderAvs}
                    />
                  ))}

                {jobs.length > props.paging.pageSize && (
                  <Pager
                    paging={props.paging}
                    onPage={props.onPage}
                    jobCount={(props.filtered || jobs).length}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      )}

      {hasJobs === false && (
        <Container maxWidth="lg">
          <div className="centerIt">
            <div className="chattrCard noJobs">
              No positions are currently open. Check back soon!
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default ListBasic;
