import React, { useState, useEffect } from 'react';
import { Marker } from '@react-google-maps/api';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useTheme, useMediaQuery, Typography } from '@material-ui/core';
import BrandThemes from '../wrappers/BrandThemes';
import pckge from '../../package.json';

import DataHelper from '../../helpers/dataHelper';
import utils from '../../helpers/utils';

import FranchiseHeader from './FranchiseHeader';
import FranchiseSearch from './FranchiseSearch';
import LocationCard from './LocationCard';
import JobListing from './JobListing';
import GoogleMap from '../GoogleMap';

import ChattrLoadScript from '../LoadScript';

const { version } = pckge;

const FranchiseJobsList = (props) => {
  const { brand, int } = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultCenter = { lat: 39.8097343, lng: -98.5556199 };

  const [passThru, setPassThru] = useState({
    source: props.source,
    campaign: props.campaign,
    'candidate.passedPhone': props.phone,
    browser: null,
    jobViewId: null,
    deviceType: null,
    theme: props.theme,
    appId: props.appId,
    tid: props.tid,
    etd: props.etd,
    fbclid: props.fbclid,
    gclid: props.gclid,
    referer: props.referer,
    portalVer: version,
    channel: props.channel,
    referrerId: props.referrerId,
    referralId: props.referralId,
  });

  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [job, setJob] = useState(null);

  const [locations, setLocations] = useState([]);
  const [distance, setDistance] = useState(25);

  // we'll use 2 states to track zipCode; one for the input
  // and one will be the official "search" zipcode
  const [zipCode, setZipCode] = useState(null);
  const [searchZipCode, setSearchZipCode] = useState(null);

  const [center, setCenter] = useState(defaultCenter);
  const [centerMarker, setCenterMarker] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      const resp = await DataHelper.getFranchiseJobs(props.config, props.org.id, int);
      setJobs(resp);

      const myLocations = [];

      for (let i = 0; i < resp.length; i++) {
        const myJob = resp[i];
        const locCheck = myLocations.find((x) => x.id === myJob.locationId);

        if (!locCheck) {
          myLocations.push({
            id: myJob.locationId,
            ...myJob.location,
            show: false,
          });
        }
      }
      setLocations(myLocations);

      const deviceData = await DataHelper.getDeviceData();

      // were we able to retrieve the zipcode from the user's browser info
      // if there's a delay here; don't set these if they've already put in a zipcode
      if (!searchZipCode) {
        if (deviceData.zip && !(props.query && props.query.ignoreZip)) {
          setCenter({ lat: deviceData.latitude, lng: deviceData.longitude });
          setCenterMarker({
            lat: deviceData.latitude,
            lng: deviceData.longitude,
          });

          setZipCode(deviceData.zip);
          setSearchZipCode(deviceData.zip);
        }
      }
    };

    fetchJobs();
    console.log(`v: ${version}`);
  }, []);

  // the search zip code is officially set when someone hits "search"
  useEffect(async () => {
    // if we have a new zipcode we need to get the center coords
    let resp;

    if (searchZipCode) resp = await utils.getYummyCenter(searchZipCode);

    if (resp) {
      setCenter(resp);
      setCenterMarker(resp);

      let locationsCopy = _.cloneDeep(locations);

      locationsCopy = locationsCopy.map((x, idx) => {
        const dist = utils.getDistance(x.coords, resp);
        return { ...x, dist, opened: false, show: dist <= distance };
      });
      locationsCopy = _.sortBy(locationsCopy, (x) => x.dist);

      // find the first "show" and mark that has "opened"
      // let firstShow = locationsCopy.find(x => x.show);
      // if (firstShow)
      //     firstShow.opened = true;

      setLocations(locationsCopy);
    }
  }, [searchZipCode]);

  useEffect(() => {
    let locationsCopy = _.cloneDeep(locations);

    locationsCopy = locationsCopy.map((x, idx) => {
      const dist = utils.getDistance(x.coords, centerMarker);
      return { ...x, dist, opened: false, show: dist <= distance };
    });
    locationsCopy = _.sortBy(locationsCopy, (x) => x.dist);

    // find the first "show" and mark that has "opened"
    // let firstShow = locationsCopy.find(x => x.show);
    // if (firstShow)
    //     firstShow.opened = true;

    setLocations(locationsCopy);
  }, [distance]);

  const onSearch = () => {
    setSearchZipCode(zipCode);
  };

  const onLocationSelect = (locationId, doSlide = false) => {
    let locationsCopy = _.cloneDeep(locations);
    const currentOpened = locationsCopy.find((x) => x.opened);

    locationsCopy = locationsCopy.map((x) => ({ ...x, opened: false }));
    const location = locationsCopy.find((x) => x.id === locationId);

    if (currentOpened?.id !== locationId) {
      location.opened = true;

      if (doSlide) {
        const locContainer = document.getElementById('locationContainer');
        const elem = document.getElementById(locationId);

        if (elem) {
          locContainer.scrollTo({ top: elem.offsetTop, behavior: 'smooth' });
        }
      }
    }

    setCenter(location.coords);
    setLocations(locationsCopy);
  };

  const doViewDetail = (myJobId) => (event) => {
    setJobId(myJobId);
  };
  useEffect(async () => {
    if (jobId) {
      const myJob = jobs.find((x) => x.id === jobId);
      const fullJob = await DataHelper.getJob(props.config, myJob.orgId, myJob.id);
      setJob(fullJob);
    }
  }, [jobId]);

  const goBack = () => {
    setJob(null);
    setJobId(null);
  };

  return (
    <BrandThemes brand={brand}>
      <div className="theme theme-franchise">
        <ChattrLoadScript url={props.config.chattrbotJs} isRecruitics={props.isRecruitics} />

        <FranchiseHeader
          companyLogo={brand.logoUrl}
          companyBackground={brand.themeHeroUri}
          companyName={brand.name}
        />

        {jobId && job && <JobListing job={job} goBack={goBack} brand={brand} />}

        {!jobId && (
          <FranchiseSearch
            zipCode={zipCode}
            setZipCode={setZipCode}
            distance={distance}
            setDistance={setDistance}
            onSearch={onSearch}
          />
        )}

        {!jobId && (
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid item sm={12} md={6}>
                {searchZipCode && (
                  <div
                    id="locationContainer"
                    className={
                      isSm
                        ? 'chattrCard locationContainer noBorder'
                        : 'chattrCard locationContainer'
                    }
                  >
                    {locations.filter((x) => x.show).length === 0 && (
                      <div
                        style={{ textAlign: 'center', width: '100%', padding: isSm ? '24px' : '0' }}
                      >
                        <Typography variant="body2">
                          No Locations found in this range. Please expand your search.
                        </Typography>
                      </div>
                    )}

                    {locations
                      .filter((x) => x.show)
                      .map((x, idx) => (
                        <LocationCard
                          idx={idx}
                          brand={brand}
                          passThru={passThru}
                          location={x}
                          jobs={jobs.filter((j) => j.locationId === x.id)}
                          onLocationSelect={onLocationSelect}
                          doViewDetail={doViewDetail}
                          key={idx}
                        />
                      ))}
                  </div>
                )}
              </Grid>
              <Grid item sm={12} md={6}>
                <Hidden smDown>
                  <div className="chattrCard locationContainer" id="mapContainer">
                    <GoogleMap
                      center={center}
                      height="50vh"
                      zoom={_.isEqual(center, defaultCenter) ? 4 : 10}
                      markers={[
                        <Marker
                          position={centerMarker}
                          icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                          }}
                        />,
                        ...locations
                          .filter((x) => x.show)
                          .map((x, idx) => (
                            <Marker
                              onClick={() => {
                                onLocationSelect(x.id, true);
                              }}
                              key={idx}
                              animation={window.google ? window.google.maps.Animation.DROP : null}
                              position={x.coords}
                              icon={{
                                url: x.opened
                                  ? 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
                                  : 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png',
                              }}
                            />
                          )),
                      ]}
                    />
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    </BrandThemes>
  );
};

export default FranchiseJobsList;
