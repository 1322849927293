import React, { useState, useEffect } from 'react';

import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import IconUp from '@material-ui/icons/KeyboardArrowUp';
import IconDown from '@material-ui/icons/KeyboardArrowDown';

import LocationJob from './LocationJob';

const LocationCard = React.memo(
  ({ passThru = {}, location, jobs, brand, onLocationSelect, doViewDetail = () => {} }) => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const [showJobs, setShowJobs] = useState(location.opened);
    const [showIcon, setShowIcon] = useState(<IconDown />);

    useEffect(() => {
      if (showJobs) {
        setShowIcon(<IconUp />);
      } else {
        setShowIcon(<IconDown />);
      }
    }, [showJobs]);

    useEffect(() => {
      setShowJobs(location.opened);
    }, [location]);

    return (
      <div
        id={location.id}
        className={location.opened && !isXs ? 'chattrCard locationOpen' : 'chattrCard'}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item md={2}>
            <div>
              {location.opened && !isXs ? (
                <img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" />
              ) : (
                <img src="https://maps.google.com/mapfiles/ms/icons/orange-dot.png" />
              )}
            </div>
          </Grid>

          <Grid item md={10} lg={6}>
            <Typography variant="body2">
              <strong>{location.street}</strong>
            </Typography>
            <Typography variant="body1">
              {location.city}, {location.state} {location.zipCode} - {location.dist.toFixed(2)} mi.
            </Typography>
          </Grid>

          <Grid container item md={12} lg={4} justifyContent="flex-end">
            <Hidden xsDown>
              <Button
                className="button-expand"
                variant="contained"
                color="secondary"
                onClick={() => onLocationSelect(location.id)}
                endIcon={showIcon}
              >
                View Openings
              </Button>
            </Hidden>
          </Grid>

          {(showJobs || isXs) && (
            <Grid item xs={12}>
              <Grid container spacing={3} alignItems="center">
                {jobs.map((x, idx) => (
                  <LocationJob
                    key={idx}
                    job={x}
                    doViewDetail={doViewDetail}
                    lastJob={idx === jobs.length - 1}
                    passThru={passThru}
                    brand={brand}
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  },
);

export default LocationCard;
