import React from 'react';
import _filter from 'lodash/filter';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Autocomplete from './AutocompleteDropDown';

const JobFilters = React.memo(
  ({ filters = {}, states, cities, handleFilterChange = () => {}, referrer }) => {
    if (filters.stateFilter !== '') {
      cities = _filter(cities, (x) => x.state.toLowerCase() === filters.stateFilter.toLowerCase());
    }

    return (
      <div className="filter-card filter-card-vertical">
        <Grid className="filter-card-container" container spacing={1}>
          <Grid item xs={12} className="filter-card-title">
            <Typography variant="h6" align="center">
              Job Filters
            </Typography>
          </Grid>

          {referrer && (
            <Grid container item xs={12} justifyContent="center" className="filter-card-subtitle">
              <Typography variant="caption">
                <strong>Referred By:</strong> {referrer}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} className="filter-card-state">
            <Autocomplete
              variant="outlined"
              label="State"
              req
              value={filters.stateFilter || ''}
              onChange={handleFilterChange('stateFilter')}
              options={states}
            />
          </Grid>
          <Grid item xs={12} className="filter-card-city">
            <Autocomplete
              variant="outlined"
              label="City"
              req
              value={filters.cityFilter || ''}
              onChange={handleFilterChange('cityFilter')}
              options={cities}
            />
          </Grid>
        </Grid>
      </div>
    );
  },
);

export default JobFilters;
