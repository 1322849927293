import React from 'react';

import { useTheme, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TextInput from '../TextInput';
import DropDown from '../DropDown';

const FranchiseSearch = React.memo(({ zipCode, setZipCode, distance, setDistance, onSearch }) => {
  const isDisabled = !(!Number.isNaN(zipCode) && zipCode && zipCode.length === 5);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <div
          className="filter-card filter-card-horizontal"
          style={{ marginTop: isSm ? '32px' : '-48px' }}
        >
          <Grid className="filter-card-container" container spacing={3}>
            <Grid item xs={12} className="filter-card-title">
              <Typography variant="h6">Search for Job</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput
                variant="outlined"
                label="Zip Code"
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode || ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DropDown
                label="Distance"
                value={distance}
                variant="outlined"
                onChange={(e) => setDistance(e.target.value)}
                options={[
                  { label: '10 miles', value: 10 },
                  { label: '25 miles', value: 25 },
                  { label: '50 miles', value: 50 },
                  { label: '75 miles', value: 75 },
                  { label: '100 miles', value: 100 },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button color="primary" variant="contained" onClick={onSearch} disabled={isDisabled}>
                Search
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
});

export default FranchiseSearch;
