import React from 'react';

import Container from '@material-ui/core/Container';

import HeroHeader from './HeroHeader';
import JobDescription from './JobDescription';
import JobTable from './JobTable';

const ListHero = (props) => {
  const { org } = props;

  return (
    <div className="theme theme-hero">
      <HeroHeader {...props} tagLine={`Careers with ${org.name}`} />
      <JobDescription org={org} moveUp={false} naked width="lg" />
      <Container maxWidth="lg">
        <JobTable {...props} />
      </Container>
    </div>
  );
};

export default ListHero;
