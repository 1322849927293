import React from 'react';

import Container from '@material-ui/core/Container';
import BlockHeader from './BlockHeader';
import JobDescription from './JobDescription';
import JobFiltersHorizontal from '../JobFiltersHorizontal';
import JobTable from './JobTable';

const ListBlock = (props) => {
  return (
    <div className="theme theme-block">
      <BlockHeader {...props} tagline="Join Our Team!">
        <JobFiltersHorizontal {...props} />
      </BlockHeader>

      <JobDescription width="lg" org={props.org} moveUp={false} />

      <Container maxWidth="lg">
        <JobTable {...props} />
      </Container>
    </div>
  );
};

export default ListBlock;
