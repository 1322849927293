import React from 'react';
import Link from 'next/link';

import Button from '@material-ui/core/Button';

import Chattrtar from './Chattrtar';

const JobItem = React.memo(({ org, brand, job, query = {}, queryString = '', showHeaderAvs }) => {
  return (
    <div className="job-list-item">
      <div className="job-list-item-left">
        <div className="job-list-title">
          {showHeaderAvs && job.location?.brand?.botAvatarUrl && (
            <Chattrtar avatarUri={job.location?.brand?.botAvatarUrl} size="small" />
          )}
          {job.title}
        </div>
        <div className="job-list-details">
          {`${job.location?.brand?.name || brand.name}${job.type ? ` - ${job.type}` : ''}`}
        </div>
      </div>
      <div className="job-list-item-right">
        <div className="job-list-street">{`${job.location.street}`}</div>
        <div className="job-list-location">{`${job.location.city}, ${job.location.state}, ${job.location.zipCode}`}</div>

        <div className="job-button">
          <Link
            href={{ pathname: '/[orgId]/[jobId]', query }}
            as={`/${org.url}/${job.id}${queryString}`}
            passHref
            prefetch={false}
          >
            <Button className="theme-button" color="primary" variant="contained">
              View Job
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
});

export default JobItem;
