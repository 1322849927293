import React from 'react';

import Hidden from '@material-ui/core/Hidden';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PageWrapper from '../wrappers/PageWrapper';
import JobItem from '../JobItem2';
import JobFilters from '../JobFilters';
import Pager from '../Pager';

const ListClassic = ({
  org,
  jobs,
  hasJobs,
  showFilters,
  filtered,
  filters,
  states,
  cities,
  handleFilterChange,
  referrer,
  paging,
  onPage,
  source,
  campaign,
  phone,
  query,
  queryString,
  brand,
  uniqueAvatars = [],
  showHeaderAvs = false,
}) => {
  return (
    <div className="theme theme-classic">
      {org.description && (
        <div className="org-desc-wrapper">
          <Container maxWidth="lg">
            <div className="orgDesc" dangerouslySetInnerHTML={{ __html: org.description }} />
          </Container>
        </div>
      )}
      {hasJobs === true && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={showFilters ? 4 : 12}>
              {showFilters === true && (
                <div className="filters-wrapper">
                  <JobFilters
                    filters={filters}
                    states={states}
                    cities={cities}
                    handleFilterChange={handleFilterChange}
                    referrer={referrer}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={showFilters ? 8 : 12}>
              <div className="jobs-container">
                {(filtered || jobs).length > paging.pageSize && (
                  <Pager paging={paging} onPage={onPage} jobCount={(filtered || jobs).length} />
                )}
                {(filtered || jobs)
                  .slice(paging.startIndex, paging.startIndex + paging.pageSize)
                  .map((job, idx) => {
                    return (
                      <JobItem
                        key={idx}
                        org={org}
                        job={job}
                        brand={brand}
                        source={source}
                        campaign={campaign}
                        phone={phone}
                        query={query}
                        queryString={queryString}
                        uniqueAvatars={uniqueAvatars}
                        showHeaderAvs={showHeaderAvs}
                      />
                    );
                  })}

                {(filtered || jobs).length > paging.pageSize && (
                  <Pager paging={paging} onPage={onPage} jobCount={(filtered || jobs).length} />
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
      {hasJobs === false && (
        <Container maxWidth="lg">
          <div className="centerIt">
            <div className="chattrCard noJobs">
              No positions are currently open. Check back soon!
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default ListClassic;
