import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Typography } from '@material-ui/core';

// eslint-disable-next-line react/function-component-definition
const TextInput = function TextInput({
  label,
  value = '',
  onChange,

  onClick = () => {},
  onFocus = () => {},
  req,
  inputType = 'text',
  disabled = false,
  readOnly = false,
  rows = 1,
  prefix,
  suffix,
  error = {},
  warning = {},
  helperText = '',
  helpKey,
  disableLabelTransform = false,
  disableAutocomplete = false,
  autoComplete,
  placeholder,
  name = '',
  id = '',
  variant = 'outlined',
  onPaste = () => {},
  style = {},
  inputStyle = {},
  classes = '',
  onBlur = () => {},
  maxLength,
  onKey = () => {},
  autoFocus = false,
}) {
  const inputProps = {};
  let isMultiline = false;

  if (prefix) {
    inputProps.startAdornment = <InputAdornment position="start">{prefix}</InputAdornment>;
  }

  if (suffix) {
    inputProps.endAdornment = (
      <InputAdornment position="end" style={{ marginLeft: 0 }}>
        {suffix}
      </InputAdornment>
    );
  }

  if (readOnly) {
    inputProps.readOnly = readOnly;
  }

  const trueInputProps = {};

  const inputLabelProps = {
    shrink: false,
    required: req,
  };

  if (disableLabelTransform) {
    inputLabelProps.style = {
      textTransform: 'none',
    };
  }

  if (disableAutocomplete) trueInputProps.autoComplete = 'new-password';

  if (rows > 1) {
    isMultiline = true;
    trueInputProps.maxLength = 9999;
  }

  if (Object.keys(inputStyle)?.length > 0) {
    trueInputProps.style = inputStyle;
  }

  let isError = false;
  if (error.isValid !== undefined && !error.isValid) isError = true;

  let isWarning = false;
  if (warning.isValid !== undefined && !warning.isValid) isWarning = true;

  // instead of red start for required, we'll add "optional" to non-required fields
  let myLabel = label;
  if (typeof req === 'boolean' && req === false && label && label.length > 0) {
    myLabel = `${label} (optional)`;
  }

  if (maxLength > 0) {
    trueInputProps.maxLength = maxLength;
  }

  return (
    <>
      <TextField
        label={myLabel}
        value={value}
        autoComplete={autoComplete}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onPaste={onPaste}
        onKeyPress={onKey}
        onChange={onChange}
        margin="normal"
        variant={variant}
        fullWidth
        // I've disabled this to allow for placeholder text to show
        // I don't think it has caused any issues, but leaving commented just incase - Charlie
        // InputLabelProps={{ shrink: false }}
        InputLabelProps={inputLabelProps}
        inputProps={trueInputProps}
        required={false}
        type={inputType}
        disabled={disabled}
        rows={rows}
        error={isError || isWarning}
        multiline={isMultiline}
        helperText={helperText}
        // eslint-disable-next-line
        InputProps={{ ...inputProps, style }}
        placeholder={placeholder}
        name={name}
        id={id}
        className={classes}
        autoFocus={autoFocus}
      />
      {isWarning && (
        <Typography variant="caption" style={{ color: '#D45163' }}>
          {warning.msg}
        </Typography>
      )}
    </>
  );
};

export default TextInput;
