import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

import SEO from './SEO';

import PageWrapper from './wrappers/PageWrapper';

import ListBasic from './themes/ListBasic';
import ListBlock from './themes/ListBlock';
import ListClassic from './themes/ListClassic';
import ListHero from './themes/ListHero';

import DataHelper from '../helpers/dataHelper';
import BrandThemes from './wrappers/BrandThemes';

const JobsList = (props) => {
  const { theme, org, brand, brandQueryString } = props;

  const router = useRouter();

  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useState({
    stateFilter: props.qsState.toLowerCase(),
    cityFilter: props.qsCity.toLowerCase(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [filtered, setFiltered] = useState(null);
  const [paging, setPaging] = useState({ startIndex: 0, pageSize: 25 });
  const [uniqueAvatars, setUniqueAvatars] = useState([]);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [hasJobs, setHasJobs] = useState(null);
  const [locationLock, setLocationLock] = useState(props.locationLocked);

  useEffect(() => {
    const getJobs = async () => {
      setIsLoading(true);
      const brandQS = brandQueryString === 'all' ? '' : brandQueryString || '';
      const resp = await DataHelper.getJobs2(
        props.config,
        org.id,
        props.source,
        props.locationId,
        brandQS,
        props.int || null,
      );

      // check if there is only one job returned; if there is then let's just redirect to the
      // job detail of that one job
      if (resp && resp.jobs && resp.jobs.length === 1) {
        router.push(`/${props.orgUrl}/${resp.jobs[0].id}${props.queryString}`);
      }

      if (resp) {
        setStates(resp.states);
        setCities(resp.cities);
        setJobs(resp.jobs);
        setShowFilters(resp.showFilters);
        setHasJobs(resp.hasJobs);
      }

      if (resp.city || resp.state) {
        const filtersCopy = { ...filters };

        if (resp.state) filtersCopy.stateFilter = resp.state.toLowerCase();
        if (resp.city) filtersCopy.cityFilter = resp.city.toLowerCase();

        setFilters(filtersCopy);
      } else if (props.qsState.length === 0 && props.qsCity.length === 0) {
        const deviceData = await DataHelper.getDeviceData();

        if (deviceData.region_code && resp.showFilters) {
          // check to see if this matches anything in the state list
          const stateCheck = resp.states.find((x) => x.value === deviceData.region_code);

          // if we found the state in the list
          if (stateCheck) {
            const filtersCopy = { ...filters };
            filtersCopy.stateFilter = stateCheck.value.toLowerCase();
            setFilters(filtersCopy);
          }
        }
      }
      setIsLoading(false);
    };

    getJobs();
  }, []);

  useEffect(() => {
    doFiltering();
  }, [filters, jobs]);

  const handleFilterChange = (name) => (event, selected) => {
    // if they toggle the filters then turn off the location locking from this point forward
    setLocationLock(false);

    let filterValue = selected && selected.value ? selected.value : event.target.value;
    if (filterValue) filterValue = filterValue.toLowerCase();

    const filtersCopy = { ...filters };
    filtersCopy[name] = filterValue || '';

    setFilters(filtersCopy);
  };
  const doFiltering = () => {
    if (jobs.length > 0) {
      setIsLoading(true);
      let filteredJobs = null;

      if (filters.stateFilter !== '' || filters.cityFilter !== '') {
        filteredJobs = [...jobs];

        if (filters.stateFilter !== '') {
          const tempFiltered = filteredJobs?.filter(
            (item) => item.location.state.toLowerCase() === filters.stateFilter,
          );

          if (tempFiltered && tempFiltered.length > 0) {
            filteredJobs = tempFiltered;
          }
        }
        if (filters.cityFilter !== '') {
          const tempFiltered = filteredJobs?.filter(
            (item) => item.location.city.toLowerCase() === filters.cityFilter,
          );

          if (tempFiltered && tempFiltered.length > 0) {
            filteredJobs = tempFiltered;
          }
        }
      }

      // if we have a locationId; then let's sort that location to the top of the list
      if (props.locationId) {
        if (locationLock) {
          // if we're "locked" to a location ONLY shows jobs for that location
          const locationJobs = filteredJobs?.filter((x) => x.locationId === props.locationId);

          // if for whatever reason this returns no jobs then just sort by location
          if (!locationJobs || locationJobs.length === 0) {
            filteredJobs = _sortBy(filteredJobs, (x) =>
              x.locationId === props.locationId ? 0 : 1,
            );
          } else {
            filteredJobs = locationJobs;
          }
        } else {
          filteredJobs = _sortBy(filteredJobs, (x) => (x.locationId === props.locationId ? 0 : 1));
        }
      }

      // we want to retrieve all unique avatarUri so we can show them at the top of the page
      // do this post-filtering

      let uniqAvs = (filteredJobs || jobs)
        ?.filter((x) => x.location.brand?.botAvatarUrl && !x.location.brand?.default)
        .map((x) => x.location.brand?.botAvatarUrl);
      uniqAvs = _uniqBy(uniqAvs, (x) => x);
      if (uniqAvs.length > 1) {
        setUniqueAvatars(uniqAvs);
      } else {
        setUniqueAvatars([]);
      }

      setFiltered(filteredJobs);
      setIsLoading(false);
    }
  };

  const onPage = (direction) => {
    const pagingCopy = { ...paging };

    if (direction === 'back') {
      pagingCopy.startIndex -= pagingCopy.pageSize;
    } else if (direction === 'forward') {
      pagingCopy.startIndex += pagingCopy.pageSize;
    }

    if (pagingCopy.startIndex < 0) {
      pagingCopy.startIndex = 0;
    }

    setPaging(pagingCopy);
  };

  const JobListProps = {
    ...props,
    jobs: filtered || jobs,
    hasJobs,
    showFilters,
    filters,
    states,
    cities,
    handleFilterChange,
    paging,
    onPage,
    uniqueAvatars,
    showHeaderAvs: uniqueAvatars?.length > 1 && !brandQueryString, // we only want to show avatars on the root org list page
  };

  return (
    <>
      <SEO org={org} brand={brand} currentUrl={`${props.config.basePath}${router.asPath}`} />

      <BrandThemes brand={brand}>
        <PageWrapper
          brandName={brand?.name}
          logoUrl={brand?.logoUrl}
          uniqueAvatars={uniqueAvatars}
          showHeaderAvs={uniqueAvatars?.length > 1 && !brandQueryString}
          isLoading={isLoading}
          color={brand?.orgColor || '#30C67C'}
        >
          {theme === 'basic' && <ListBasic {...JobListProps} />}
          {theme === 'block' && <ListBlock {...JobListProps} />}
          {theme === 'classic' && <ListClassic {...JobListProps} />}
          {theme === 'hero' && <ListHero {...JobListProps} />}
        </PageWrapper>
      </BrandThemes>
    </>
  );
};

export default JobsList;
