import React from 'react';

import IconBack from '@material-ui/icons/ArrowBackIos';
import IconForward from '@material-ui/icons/ArrowForwardIos';

import Button from '@material-ui/core/Button';

const Pager = React.memo(({ paging, jobCount, onPage = () => {} }) => {
  let pageEnd = paging.startIndex + paging.pageSize;
  if (pageEnd > jobCount) pageEnd = jobCount;

  return (
    <div className="pager">
      <div className="pageBack">
        <Button
          onClick={() => onPage('back')}
          variant="contained"
          disabled={paging.startIndex === 0}
          startIcon={<IconBack />}
        />
      </div>
      <div className="pageNav pageDetails">
        Viewing {paging.startIndex + 1} to {pageEnd} of {jobCount}
      </div>
      <div className="pageForward">
        <Button
          onClick={() => onPage('forward')}
          variant="contained"
          disabled={pageEnd >= jobCount}
          endIcon={<IconForward />}
        />
      </div>
    </div>
  );
});

export default Pager;
