import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

const ApplyButton = React.memo(
  ({
    orgId,
    jobId,
    passThru,
    isRecruitics = false,
    size = 'large',
    label = 'Quick Apply Now!',
    fullWidth = false,
    brandName,
  }) => {
    const [didInit, setDidInit] = useState(false);

    const launchBot = () => {
      console.log('launch-attempt');

      if (!didInit) {
        console.log('launch-no-init');
        if (window.chattr !== undefined && window.chattr.init !== undefined) {
          window.chattr.init({ appKey: orgId, jobId, passThru, brandName });

          console.log('init-complete-launch');

          setDidInit(true);
        }
      }

      window.chattr.launchInit({ appKey: orgId, jobId, passThru, brandName });

      if (isRecruitics === true && window.rx !== undefined) {
        try {
          // window.rx.trigger({ triggerName: 'apply', jobId: jobId });
        } catch (error) {
          console.log(error);
        }
      }
    };

    useEffect(() => {
      try {
        console.log('init-attempt');
        if (window.chattr !== undefined && window.chattr.init !== undefined) {
          window.chattr.init({ appKey: orgId, jobId, passThru, brandName });
          console.log('init-complete');
          setDidInit(true);
        } else {
          console.log('init-none');
        }
      } catch (error) {
        console.log(error);
      }
    }, [orgId, jobId, passThru, brandName]);

    return (
      <Button
        size={size}
        href=""
        onClick={() => launchBot()}
        variant="contained"
        fullWidth={fullWidth}
        color="primary"
      >
        {label}
      </Button>
    );
  },
);

export default ApplyButton;
